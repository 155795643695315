import api_server, {GetListConfig} from '@/plugins/api_server';

export default {
    namespaced: true,

    state: () => {
        return {
            list: [],
            totalItems: 0,
            currentPage: 0,
            isLoadingList: false,
            notificationWindowOpen: false
        }
    },

    setters: {

    },

    getters: {
        getWindowOpen: (state) => {
            return state.notificationWindowOpen;
        },

        getNotifications: (state) => {
            return state.list;
        },

        getUnreadNotifications: (state) => {
            if (state.list.data) {

                return state.list.data.filter((item) => {
                    return !item.read_at
                });
            } else {
                return []
            }
        },

        getIsLoading: (state) => {
            return state.isLoadingList;
        },

    },

    mutations: {
        setListData(state, notifications) {
            state.list = notifications;
        },

        setWindowOpen(state, isOpen) {
            state.notificationWindowOpen = isOpen;
        },

        setIsLoadingList(state, isLoadingList) {
            state.isLoadingList = isLoadingList;
        },

        removeNotification(state, notification) {
            state.list.data.splice(state.list.data.indexOf(notification), 1)
        }
    },

    actions: {
        toggleWindow({commit, dispatch}, isOpen) {
            commit('setWindowOpen', isOpen)

            if (isOpen) {
                dispatch("markAllAsRead");
            }
        },

        readList({commit, state, dispatch}, params, filter) {
            commit('setIsLoadingList', true);

            return api_server.getClient()
                .then((client) => {
                    return client.ListNotifications(params, filter, GetListConfig())
                        .then((list) => {
                            commit("setListData", list.data)
                            return list.data;
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => {
                            commit('setIsLoadingList', false)
                        });
                })
        },

        markAllAsRead({commit, dispatch, state}) {
            if (state.list.data.length === 0) {
                return;
            }

            return api_server.getClient()
                .then((client) => {
                    return client.ReadNotificationsSince(null, {last_id: state.list.data[0].id}, GetListConfig())
                        .then((list) => {
                            // Do something after network request?
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => {
                        });
                })
        }
    }
}