<template>
  <div class="ml-3 relative"
       v-click-outside="() => toggleWindow(false)"
       @click.prevent="clickedNotifications">
    <button
        class="p-1 bg-indigo-600 rounded-full text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white"
        @click.prevent="clickedNotifications">

      <span class="sr-only">View notifications</span>

      <!-- Heroicon name: bell -->
      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
           stroke="currentColor" aria-hidden="true"
           @click.prevent="clickedNotifications"
      >
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>
      </svg>


    </button>

    <span class="absolute top-0 right-0 block h-3.5 w-3.5 rounded-full ring-2 ring-white bg-red-400"
          v-if="unreadNotifications.length > 0"></span>

    <transition
        enter-active-class="transition ease-out duration-100"
        enter-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95">

      <div
          class="origin-top-l absolute right-0 mt-2 w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
          v-if="windowOpen">
        <div class="">
          <!--
            Notification panel, show/hide based on alert state.

            Entering: "transform ease-out duration-300 transition"
              From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              To: "translate-y-0 opacity-100 sm:translate-x-0"
            Leaving: "transition ease-in duration-100"
              From: "opacity-100"
              To: "opacity-0"
          -->
          <div
              class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
              v-for="notification in notifications.data">
            <div class="p-4">
              <div class="flex items-start">
                <div class="flex-shrink-0">
                  <!-- Heroicon name: outline/check-circle -->
                  <svg class="h-6 w-6 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                       stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                  </svg>
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p class="text-sm font-medium text-gray-900">
                    {{ notification.data.title }}
                  </p>
                  <p class="mt-1 text-sm text-gray-500">
                    {{ notification.data.body }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ClickOutside from 'vue-click-outside';

export default {
  name: "NotificationList",

  data() {
    return {
      poll_watcher: null
    }
  },

  created() {
    this.loadListApi();

    if (this.poll_watcher == null) {
      this.poll_watcher = setInterval(this.loadListApi.bind(this), 45000);
    }
  },

  beforeDestroy() {
    if (this.poll_watcher !== null) {
      clearInterval(this.poll_watcher);
    }
  },

  methods: {
    clickedNotifications() {
      this.toggleWindow(!this.windowOpen)
    },

    loadListApi() {
      this.readList({
        page: {size: 5}
      });
    },

    ...mapActions('notifications', [
      'toggleWindow',
      'readList',
      'markAllAsRead'
    ])
  },
  computed: {
    ...mapGetters('notifications', {
      isLoading: 'getIsLoading',
      unreadNotifications: 'getUnreadNotifications',
      notifications: 'getNotifications',
      windowOpen: 'getWindowOpen'
    }),
  },

  directives: {
    ClickOutside
  }
}
</script>

<style scoped>

</style>