<template>
  <router-link :to="to"
               v-slot="{ href, route, navigate, isActive, isExactActive }">

    <slot name="link">
      <a
          :href="href"
          class="text-white hover:bg-indigo-500 hover:bg-opacity-75 px-3 py-2 rounded-md text-sm font-medium"
          :class="isActive ? getNavItemActiveCss : ''"
          @click="navigate">
        <slot></slot>
      </a>
    </slot>
  </router-link>
</template>

<script>
export default {
  name: "NavigationItem",
  props: [
    'to'
  ],
  computed: {
    getNavItemActiveCss() {
      return [
        'bg-indigo-700',
        'text-white'
      ];
    },
  }
}
</script>

<style scoped>

</style>