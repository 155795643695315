import api_authentication from '@/api/Authentication';

export default {
    namespaced: true,

    state: () => {
        return {
            showProfileDropdown: false,
        }
    },

    setters: {

    },

    getters: {
        showProfileDropdownVisibility(state) {
            return state.showProfileDropdown
        }
    },

    mutations: {
        commitProfileDropdownVisibility(state, visibility) {
            state.showProfileDropdown = visibility;
        },
    },

    actions: {
        setProfileDropdownVisibility({commit}, visibility) {
            commit('commitProfileDropdownVisibility', visibility)
        }
    }
}