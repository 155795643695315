<template>
</template>

<script>
    // @ is an alias to /src
    import Navigation from "../components/Navigation/Navigation";

    export default {
        name: 'Home',
        components: {},

        created() {
            this.$router.push({
                name: 'Dashboard'
            })
        }
    }
</script>
