const URL = process.env.VUE_APP_API_SERVER

export default {
    getCurrentUser() {
        return axios.get(URL +'/api/users');
    },

    login(credentials) {
        return this.getCsrfCookie()
            .then((response) => {
                return axios.post(URL + '/api/login', credentials)
                    .then(() => {
                        return this.getCurrentUser()
                    })
            })
    },

    logout() {
        return this.getCsrfCookie()
            .then((response) => {
                return axios.post(URL + '/api/logout')
                    .then(() => {
                    })
            })
    },

    getCsrfCookie() {
        return axios.get(URL + '/api/csrf-cookie');
    }
}