import Vue from 'vue'
import './plugins/axios'
import './plugins/api_server';
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/tailwind.css'

// Select
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    // Parse user data from the local storage
    const userInfo = localStorage.getItem('user');

    if (userInfo) {
      const userData = JSON.parse(userInfo);
      this.$store.commit('authentication/setUserData', userData);
    }

    // Add a hook so that if there is any 401 errors it takes the user back to the login page
    axios.interceptors.response.use(
        response => response,
        error => {

          if (error.response.status === 401 && this.$router.currentRoute.path !== '/login') {
            this.$store.dispatch('authentication/logout');
            //
            // this.$router.push({
            //     name: 'Login'
            // })
          }

          return Promise.reject(error)
        }
    )
  }

}).$mount('#app')
