import api_server, {GetListConfig} from '@/plugins/api_server';
import _ from 'lodash';

export default {
    namespaced: true,

    state: () => {
        return {
            aggregates: [],
            list: [],

            uniqueServiceNames: [],
            uniqueTransactionTypes: [],
            reportResults: [],

            // Loading States
            isLoadingList: false,
            isLoadingAggregates: false,
            isLoadingReport: false
        }
    },

    setters: {},

    getters: {
        isLoading: (state) => {
            return state.isLoadingList ||
                state.isLoadingAggregates;
        },

        getList: (state) => {
            return state.list;
        },

        getAggregates: (state) => {
            return state.aggregates;
        },

        getUniqueServices: (state) => {
            // return _.sortBy(state.uniqueServiceNames);
            return [
                'Tea Leaf',
                'Stripe',
                'Clinical Edge',
                'Xero'
            ];
        },

        getUniqueTransactionTypes: (state) => {
            // return _.sortBy(state.uniqueTransactionTypes);
            return [
                'Adjustment',
                'Charge',
                'Fee',
                "Refund",
                'Transfer'
            ];
        },

        getReportList: (state) => {
            return state.reportResults;
        },

        getIsLoadingReport: (state) => {
            return state.isLoadingReport;
        },

        getIsLoadingAggregates: (state) => {
            return state.isLoadingAggregates;
        }
    },

    mutations: {
        setIsLoadingList(state, isLoading) {
            state.isLoadingList = isLoading;
        },

        setIsLoadingAggregates(state, isLoading) {
            state.isLoadingAggregates = isLoading
        },

        setListData(state, listData) {
            state.list = listData;
        },

        setAggregateData(state, listData) {
            state.aggregates = listData
        },

        insertIntoList(state, data, index) {
            state.list.splice(index, 0, data);
        },

        insertUniqueService(state, data) {
            if (! state.uniqueServiceNames.includes(data)) {
                state.uniqueServiceNames.push(data);
            }
        },

        insertTransactionTypes(state, data) {
            if (! state.uniqueTransactionTypes.includes(data)) {
                state.uniqueTransactionTypes.push(data);
            }
        },

        setIsLoadingReport(state, isLoading) {
            state.isLoadingReport = isLoading;
        },

        setReportData(state, data) {
            state.reportResults = data;
        }
    },

    actions: {
        readReport({commit}, filter) {
            commit('setIsLoadingReport', true);

            return api_server.getClient()
                .then((client) => {
                    return client.ReportDailyTotalsReport(filter, null, GetListConfig())
                        .then((list) => {
                            commit("setReportData", list.data)
                            return list.data;
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => {
                            commit('setIsLoadingReport', false)
                        });
                })
        },

        readList({commit, state}, filters) {
            commit('setIsLoadingList', true);

            return api_server.getClient()
                .then((client) => {
                    return client.ListUserIntegrationRecordInputs(filters, null, GetListConfig())
                        .then((list) => {
                            commit("setListData", list.data.data)
                            return list.data.data;
                        })
                        .then((items) => {
                            const groupedData = _.groupBy(items, 'reporting_date');

                            const secondGrouped = Object.keys(groupedData).reduce((carry, key) => {
                                const groupedServiceName = _.groupBy(groupedData[key], 'transaction_type');

                                // Add to the list of available services
                                _.forEach(groupedData[key], function (i) {
                                    if (!state.uniqueServiceNames.includes(i['service_name'])) {
                                        commit("insertUniqueService", i['service_name']);
                                    }


                                    if (!state.uniqueTransactionTypes.includes(i['transaction_type'])) {
                                        commit("insertTransactionTypes", i['transaction_type']);
                                    }
                                })

                                carry[key] = groupedServiceName

                                return carry;
                            }, {});

                            commit("setGroupListData", secondGrouped);

                            return secondGrouped;
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => {
                            commit('setIsLoadingList', false)
                        });
                })
        },

        readAggregates({commit}, filters) {
            commit('setIsLoadingAggregates', true);

            return api_server.getClient()
                .then((client) => {
                    return client.AggregateReportDailyTotals(filters, null, GetListConfig())
                        .then((list) => {
                            commit("setAggregateData", list.data)
                            return list.data;
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => {
                            commit('setIsLoadingAggregates', false)
                        });
                })
        },
    }
}