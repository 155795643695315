<template>
    <svg :class="classes"
         :width="width"
         :height="height"
         focusable="false"
         role="img"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 576 512">
        <path fill="currentColor"
              d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
    </svg>
</template>

<script>
    export default {
        props: {
            width: {
                default: 45,
                type: Number
            },
            height: {
                default: 54,
                type: Number
            },
            classes: {
                default: () => ([
                    'fill-current',
                    'h-8',
                    'w-auto'
                ]),
                type: Array
            }
        },

        name: "Logo"
    }
</script>

<style scoped>

</style>