import api_server, {GetListConfig} from '@/plugins/api_server';

export default {
    namespaced: true,

    state: () => {
        return {
            list: [],
            view: {},

            needsReconcile: [],
            duplicateList: [],

            totalItems: 0,
            currentPage: 0,

            createErrors: {},
            updateErrors: {},

            isCreating: false,
            isLoadingItem: false,
            isLoadingList: false,
            isDeletingItem: false,
            isUpdatingItem: false,
            isLoadingNeedsReconcile: false,
            isLoadingDuplicates: false,

            needsReconcileFilter: {}
        }
    },

    setters: {

    },

    getters: {
        isLoading: (state) => {
            return state.isCreating ||
                state.isLoadingItem ||
                state.isLoadingList ||
                state.isDeletingItem ||
                state.isUpdatingItem
        },

        getIsLoadingItem: (state) => {
            return state.isLoadingItem;
        },

        getIsLoadingList: (state) => {
            return state.isLoadingList;
        },

        getIsLoadingDuplicates: (state) => {
            return state.isLoadingDuplicates
        },

        getIsDeletingItem: (state) => {
            return state.isDeletingItem;
        },

        getIsCreating: (state) => {
            return state.isCreating;
        },

        getIsUpdatingItem: (state) => {
            return state.isUpdatingItem;
        },

        getView: (state) => {
            return state.view
        },

        getList: (state) => {
            return state.list;
        },

        getIsLoadingNeedsReconcile: (state) => {
            return state.isLoadingNeedsReconcile;
        },

        getNeedsReconcile: (state) => {
            return state.needsReconcile;
        },

        getNeedsReconcileFilter: (state) => {
            return state.needsReconcileFilter
        },


    },

    mutations: {
        setNeedsReconcileFilter(state, filter) {
            state.needsReconcileFilter = filter
        },

        setListData(state, listData) {
            state.list = listData;
        },

        insertIntoList(state, data, index) {
            state.list.splice(index, 0, data);
        },

        setView(state, view) {
            state.view = view;
        },

        setCreateErrors(state, errors) {
            state.createErrors = errors;
        },

        setUpdateErrors(state, errors) {
            state.updateErrors = errors;
        },

        setIsCreating(state, isCreating) {
            state.isCreating = isCreating;
        },

        setIsUpdating(state, isUpdating) {
            state.isUpdating = isUpdating;
        },

        setIsDeleting(state, isDeleting) {
            state.isDeleting = isDeleting;
        },

        setIsLoadingItem(state, isLoadingItem) {
            state.isLoadingItem = isLoadingItem;
        },

        setIsLoadingList(state, isLoadingList) {
            state.isLoadingList = isLoadingList
        },

        setIsLoadingNeedsReconcile(state, isLoadingNeedsReconcile) {
            state.isLoadingNeedsReconcile = isLoadingNeedsReconcile;
        },

        setNeedsReconcile(state, itemsNeedReconcile) {
          state.needsReconcile = itemsNeedReconcile;
        },

        setIsLoadingDuplicates(states, loading) {
            state.isLoadingDuplicates = loading
        },

        setDuplicateList(state, list) {
            state.duplicateList = list;
        }
    },

    actions: {
        readList({commit}, filter) {
            commit('setIsLoadingList', true);

            return api_server.getClient()
                .then((client) => {
                    return client.ListUserIntegrationRecordOutputs(filter, null,  GetListConfig())
                        .then((list) => {
                            commit("setListData", list.data)
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => {
                            commit('setIsLoadingList', false)
                        });
                })
        },

        readDuplicateList({commit}, filter) {
            commit('setIsLoadingDuplicates', true);

            const newFilter = Object.assign({}, filter, {
                'status': 'Duplicate'
            })

            return api_server.getClient()
                .then((client) => {
                    return client.ListUserIntegrationRecordOutputs(newFilter, null,  GetListConfig())
                        .then((list) => {
                            commit("setDuplicateList", list.data)
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => {
                            commit('setIsLoadingDuplicates', false)
                        });
                })
        },

        readNeedsReconcile({commit, getters}, filter) {
            commit('setNeedsReconcileFilter', filter)
            commit('setIsLoadingNeedsReconcile', true);

            return api_server.getClient()
                .then((client) => {
                    return client.GetReconciliationReport(getters.getNeedsReconcileFilter, null,  GetListConfig())
                        .then((list) => {
                            commit("setNeedsReconcile", list.data)
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => {
                            commit('setIsLoadingNeedsReconcile', false)
                        });
                })
        },

        createItem({commit}, data) {
            commit('setIsCreating', true);
            commit('setCreateErrors', {});

            return api_server.getClient()
                .then((client) => {
                    return client.CreateUserIntegrationRecordOutput(null, data)
                        .then((createdItem) => {
                            commit('setView', createdItem.data.data);
                            return createdItem.data.data
                        })
                        .catch((error) => {
                            commit('setCreateErrors', error.response.data.errors);
                            throw error;
                        })
                        .finally(() => {
                            commit('setIsCreating', false)
                        });
                })
        },

        readItem({commit}, id) {
            if (!id) {
                commit('setView', null);
                return;
            }

            commit('setIsLoadingItem', true);

            return api_server.getClient()
                .then((client) => {
                    return client.ViewUserIntegrationRecordOutput({'id': id})
                        .then((list) => {
                            commit("setView", list.data.data);
                            return list.data.data;
                        })
                        .catch((error) => {
                            commit('setView', null);
                            throw error;
                        })
                        .finally(() => {
                            commit('setIsLoadingItem', false)
                        });
                })
        },


        updateItem({commit}, id, data) {

        },

        deleteItem({commit}, id) {

        },

        reconciledItem({commit, dispatch, state}, item) {
            let newObject = Object.assign({}, state.needsReconcile, {
                data: state.needsReconcile.data.filter(function (items) {
                    return items.id !== item.id
                })
            })

            if (state.needsReconcile.data.length < 15 && state.needsReconcile.meta.total > 15) {
                dispatch("readNeedsReconcile")
            }

            commit("setNeedsReconcile", newObject)
        }
    }
}