import api_server from '@/plugins/api_server';

export default {
    namespaced: true,

    state: () => {
        return {
            list: [],
            view: {},

            createErrors: {},
            updateErrors: {},

            // Loading States
            isCreating: false,
            isLoadingList: false,
            isLoadingItem: false,
            isDeletingItem: false,
            isUpdatingItem: false,

            // Actions
            isAddingOutput: false,
            isAddingSource: false,
            isReRunningTransactions: false,
            isImportingTransactions: false,

            addSourceError: {},
            addOutputError: {},
            importTransactionErrors: {},
            isReRunningTransactionsErrors: {},
        }
    },

    setters: {

    },

    getters: {
        isLoading: (state) => {
            return state.isCreating ||
                state.isLoadingItem ||
                state.isLoadingList ||
                state.isDeletingItem ||
                state.isUpdatingItem
        },

        getIsAddingOutput: (state) => {
            return state.isAddingOutput;
        },

        getIsAddingSource: (state) => {
            return state.isAddingSource
        },

        getIsLoadingItem: (state) => {
            return state.isLoadingItem;
        },
        getIsLoadingList: (state) => {
            return state.isLoadingList;
        },
        getIsDeletingItem: (state) => {
            return state.isDeletingItem;
        },
        getIsCreating: (state) => {
            return state.isCreating;
        },
        getIsUpdatingItem: (state) => {
            return state.isUpdatingItem;
        },

        getView: (state) => {
            return state.view
        },

        getList: (state) => {
            return state.list;
        },

        getIsImportingTransactions: (state) => {
            return state.isImportingTransactions;
        },

        getIsReRunningTransactions: (state) => {
            return state.isReRunningTransactions;
        }
    },

    mutations: {
        setListData(state, listData) {
            state.list = listData;
        },

        insertIntoList(state, data, index) {
            state.list.splice(index, 0, data);
        },

        setView(state, view) {
            state.view = view;
        },

        setCreateErrors(state, errors) {
            state.createErrors = errors;
        },

        setUpdateErrors(state, errors) {
            state.updateErrors = errors;
        },

        setIsCreating(state, isCreating) {
            state.isCreating = isCreating;
        },

        setIsUpdating(state, isUpdating) {
            state.isUpdating = isUpdating;
        },

        setIsDeleting(state, isDeleting) {
            state.isDeleting = isDeleting;
        },

        setIsLoadingItem(state, isLoadingItem) {
            state.isLoadingItem = isLoadingItem;
        },

        setIsLoadingList(state, isLoadingList) {
            state.isLoadingList = isLoadingList
        },

        setIsReRunningTransactions(state, isLoading) {
            state.isReRunningTransactions = isLoading
        },

        setIsAddingSource(state, isAddingSource) {
            state.isAddingSource = isAddingSource;
        },

        setIsAddingOutput(state, isAddingOutput) {
            state.isAddingOutput = isAddingOutput;
        },

        setIsImportingTransactions(state, isImportingTransactions) {
            state.isImportingTransactions = isImportingTransactions;
        },

        setAddSourceError(state, error) {
            state.addSourceError = error
        },

        setAddOutputError(state, error) {
            state.addOutputError = error
        },

        setIsReRunningTransactionsErrors(state, error) {
            state.reRunTransactionsErrors = error;
        }
    },

    actions: {
        readList({commit}, filters) {
            commit('setIsLoadingList', true);

            return api_server.getClient()
                .then((client) => {
                    client.ListSettings()
                        .then((list) => {
                            commit("setListData", list.data.data)
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => {
                            commit('setIsLoadingList', false)
                        });
                })
        },

        createItem({commit}, data) {
            commit('setIsCreating', true);
            commit('setCreateErrors', {});

            return api_user_integration_settings
                .store(data)
                .then((created_object) => {
                    commit('setView', created_object.data);
                    return created_object;
                })
                .catch((error) => {
                    commit('setCreateErrors', error.response.data);
                    throw error;
                })
                .finally(() => {
                    commit('setIsCreating', false);
                })
        },

        readItem({commit}, id) {
            commit('setIsLoadingItem', true);

            return api_server.getClient()
                .then((client) => {
                    client.ViewSettings({'id': id})
                        .then((list) => {
                            commit("setView", list.data.data)
                        })
                        .catch((error) => {
                            commit('setView', null);
                            throw error;
                        })
                        .finally(() => {
                            commit('setIsLoadingItem', false)
                        });
                })
        },

        updateItem({commit}, id, data) {

        },

        deleteItem({commit}, id) {

        },

        addSource({commit}, id, data) {
            commit('setIsAddingSource', true);

            return api_server.getClient()
                .then((client) => {
                    client.CreateSource({'id': id}, data)
                        .then((response) => {
                            console.log(response);
                        })
                        .catch((error) => {
                            commit('setAddSourceError')
                            throw error;
                        })
                        .finally(() => {
                            commit('setIsAddingSource', false)
                        })
                })
        },

        addOutput({commit}, {id, data}) {
            commit('setIsAddingOutput', true);

            return api_server.getClient()
                .then((client) => {
                    client.CreateOutput({'id': id}, data)
                        .then((response) => {
                            console.log(response);

                            let newWindow = window.open(response.data.redirect_to, '_blank');
                            window.focus();
                        })
                        .catch((error) => {
                            commit('setAddOutputError')
                            throw error;
                        })
                        .finally(() => {
                            commit('setIsAddingOutput', false)
                        })
                })
        },

        importTransactions({commit}, {id, data}) {
            commit('setIsImportingTransactions', true);

            return api_server.getClient()
                .then((client) => {
                    return client.ImportSourceTransactions({'id': id}, data)
                        .then((response) => {
                            console.log(response.data);
                        })
                        .catch((error) => {
                            commit('importTransactionErrors', error)
                            throw error;
                        })
                        .finally(() => {
                            commit('setIsImportingTransactions', false)
                        })
                })
        },

        reRunTransactions({commit}, {id, data}) {
            commit('setIsReRunningTransactions', true)
            commit('setIsReRunningTransactionsErrors', null)

            return api_server.getClient()
                .then((client) => {
                    return client.ReRunTransactions({'id': id}, data)
                        .then((response) => {

                        })
                        .catch((error) => {
                            commit('setIsReRunningTransactionsErrors', error)
                            throw error;
                        })
                        .finally(() => {
                            commit('setIsReRunningTransactions', false);
                        })
                })
        }
    }
}