import Vue from 'vue'
import Vuex from 'vuex'
import app from '@/store/modules/app';
import authentication from './modules/authentication';
import user_integration_settings from "@/store/modules/user_integration_settings";
import user_integration_record_inputs from "@/store/modules/user_integration_setting_inputs";
import user_integration_settings_contacts from "@/store/modules/user_integration_settings_contacts";
import user_integration_record_outputs from "@/store/modules/user_integration_setting_outputs";
import notifications from "@/store/modules/notifications";
import report_daily_totals from '@/store/modules/report_daily_totals'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        authentication,
        user_integration_settings,
        user_integration_record_inputs,
        user_integration_settings_contacts,
        user_integration_record_outputs,
        notifications,
        report_daily_totals
    }
})
