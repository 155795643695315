<template>

  <nav class="ml-10 flex items-baseline space-x-4">
    <navigation-item to="/dashboard">
        Dashboard
    </navigation-item>

    <navigation-item to="/integrations">
      Integrations
    </navigation-item>

    <navigation-item to="/activities">
      Activities
    </navigation-item>
  </nav>
</template>

<script>
import NavigationItem from "@/components/Navigation/NavigationItem";
export default {
  name: "Navigation",
  components: {NavigationItem},
  methods: {

  },

}
</script>

<style scoped>

</style>