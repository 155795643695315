import api_authentication from '@/api/Authentication';
import { DateTime } from 'luxon';

export default {
    namespaced: true,

    state: () => {
        return {
            user: null,
            loginErrors: {},
            isLoggingIn: false,
            isLoadingProfile: false,
        }
    },

    setters: {},

    getters: {
        isLoggedIn: (state) => {
            return state.user != null
        },

        isLoading: (state) => {
            return state.isLoggingIn;
        },

        user: (state) => {
            return state.user;
        },

        userCurrentTime: (state) => {
            return DateTime.local().setZone(state.user.timezone)
        },
    },

    mutations: {
        setLoginErrors(state, errors) {
            state.loginErrors = errors;
        },

        setUserData(state, userData) {
            state.user = userData;
            localStorage.setItem('user', JSON.stringify(userData));
        },

        clearUserData(state) {
            state.user = null;
            localStorage.removeItem('user');
        },

        setIsLoggingIn(state, loading) {
            state.isLoggingIn = loading;
        },

        setIsLoadingProfile(state, loading) {
            state.isLoadingProfile = loading;
        }
    },

    actions: {
        login({commit}, credentials) {
            commit('setIsLoggingIn', true);
            commit('setLoginErrors', {});

            return api_authentication.login(credentials)
                .then((ApiRequest) => {
                    commit('setUserData', ApiRequest.data.data);
                })
                .catch((error) => {
                    commit('setLoginErrors', error.response.data);
                })
                .finally(() => {
                    commit('setIsLoggingIn', false)
                })
        },

        checkLogin({commit}) {
            commit('setIsLoadingProfile', true);

            return api_authentication.getCurrentUser()
                .then((request) => {
                    commit('setUserData', request.data.data);
                })
                .finally(() => {
                    commit('setIsLoadingProfile', false)
                })
        },

        logout({commit}) {
            commit('clearUserData')

            return api_authentication.logout()
                .then((ApiRequest) => {
                    window.location.href = '/login'
                })
        },
    }
}