"use strict";

import {OpenAPIClientAxios} from 'openapi-client-axios';
import qs from "qs";

export const api_server = new OpenAPIClientAxios({
    axiosConfigDefaults: window.axios.defaults,
    definition: process.env.VUE_APP_API_SERVER + '/api/open-api'
});


api_server.init();

export function GetListConfig() {
    return {
        paramsSerializer: params => {
            return qs.stringify(params, {
                arrayFormat: "brackets",
                encode: false
            });
        }
    }
}


export default api_server;
