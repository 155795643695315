import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const public_routes = [
    {
        path: '/login',
        name: 'Login',
        meta: {
            is_public: true
        },
        component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue')
    },
];

const private_routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/*webpackChunkName: "dashboard" */ '../views/Dashboard')
    },
    {
        path: '/activities',
        name: 'Activities',
        component: () => import(/*webpackChunkName: "dashboard" */ '../views/Activities')
    },
    {
        path: '/integrations',
        name: 'Settings',
        component: () => import(/*webpackChunkName: "dashboard" */ '../views/Integrations')
    },
    {
        path: '/integrations/:settings_id',
        name: 'user_integration_settings_view',
        component: () => import(/*webpackChunkName: "dashboard" */ '../views/UserIntegrationSettings/Overview')
    },

    {
        path: '/integrations/:settings_id/reconciliation',
        name: 'user_integration_settings_reconciliation',
        component: () => import(/*webpackChunkName: "dashboard" */ '../views/UserIntegrationSettings/Reconciliation')
    },
    {
        path: '/integrations/:settings_id/reports',
        name: 'user_integration_settings_reports',
        component: () => import(/*webpackChunkName: "dashboard" */ '../views/UserIntegrationSettings/Reports')
    },
    {
        path: '/integrations/:settings_id/reports/balance-report',
        name: 'user_integration_settings_reports_balance',
        component: () => import(/*webpackChunkName: "dashboard" */ '../views/UserIntegrationSettings/Reports/BalanceReport')
    },
    {
        path: '/integrations/:settings_id/contacts',
        name: 'user_integration_settings_contacts',
        component: () => import(/*webpackChunkName: "dashboard" */ '../views/UserIntegrationSettings/Contacts')
    },
    {
        path: '/integrations/:settings_id/contacts/:contact_id',
        name: 'user_integration_settings_contact_view',
        component: () => import(/*webpackChunkName: "dashboard" */ '../views/UserIntegrationSettings/Contacts')
    },
    {
        path: '/integrations/:settings_id/input-feed',
        name: 'user_integration_settings_input_feed',
        component: () => import(/*webpackChunkName: "dashboard" */ '../views/UserIntegrationSettings/InputFeed')
    },
    {
        path: '/integrations/:settings_id/input-feed/:input_id',
        name: 'user_integration_settings_view_input',
        component: () => import(/*webpackChunkName: "dashboard" */ '../views/UserIntegrationSettings/ViewInput')
    },
    {
        path: '/integrations/:settings_id/output-feed',
        name: 'user_integration_settings_output_feed',
        component: () => import(/*webpackChunkName: "dashboard" */ '../views/UserIntegrationSettings/OutputFeed')
    },
    {
        path: '/integrations/:settings_id/output-feed/:output_id',
        name: 'user_integration_settings_view_output',
        component: () => import(/*webpackChunkName: "dashboard" */ '../views/UserIntegrationSettings/ViewOutput')
    },
    {
        path: '/integrations/:settings_id/settings',
        name: 'user_integration_settings_configuration',
        component: () => import(/*webpackChunkName: "dashboard" */ '../views/UserIntegrationSettings/Settings')
    },

]

const routes = [...public_routes, ...private_routes];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    const isLoggedIn = localStorage.getItem('user')

    // This is a public route, go to the next page
    if (to.matched.some(record => record.meta.is_public)) {
        next();
        return;
    }

    // This user is logged in
    if (isLoggedIn) {
        next();
        return
    }

    // The user is not logged in and needs to go to the redirect page
    if (to.path !== '/login') {
        next('/login')
    } else {
        next();
    }
})

export default router
