import api_server, {GetListConfig} from '@/plugins/api_server';

export default {
    namespaced: true,

    state: () => {
        return {
            list: [],
            view: {},

            createErrors: {},
            updateErrors: {},

            // Loading States
            isCreating: false,
            isLoadingList: false,
            isLoadingItem: false,
            isDeletingItem: false,
            isUpdatingItem: false,
        }
    },

    setters: {},

    getters: {
        isLoading: (state) => {
            return state.isCreating ||
                state.isLoadingItem ||
                state.isLoadingList ||
                state.isDeletingItem ||
                state.isUpdatingItem
        },

        getIsLoadingItem: (state) => {
            return state.isLoadingItem;
        },

        getIsLoadingList: (state) => {
            return state.isLoadingList;
        },

        getIsDeletingItem: (state) => {
            return state.isDeletingItem;
        },

        getIsCreating: (state) => {
            return state.isCreating;
        },

        getIsUpdatingItem: (state) => {
            return state.isUpdatingItem;
        },

        getView: (state) => {
            return state.view
        },

        getList: (state) => {
            return state.list;
        }
    },

    mutations: {
        setListData(state, listData) {
            state.list = listData;
        },

        insertIntoList(state, data, index) {
            state.list.splice(index, 0, data);
        },

        setView(state, view) {
            state.view = view;
        },

        setCreateErrors(state, errors) {
            state.createErrors = errors;
        },

        setUpdateErrors(state, errors) {
            state.updateErrors = errors;
        },

        setIsCreating(state, isCreating) {
            state.isCreating = isCreating;
        },

        setIsUpdating(state, isUpdating) {
            state.isUpdating = isUpdating;
        },

        setIsDeleting(state, isDeleting) {
            state.isDeleting = isDeleting;
        },

        setIsLoadingItem(state, isLoadingItem) {
            state.isLoadingItem = isLoadingItem;
        },

        setIsLoadingList(state, isLoadingList) {
            state.isLoadingList = isLoadingList
        }
    },

    actions: {
        readList({commit}, filter) {
            commit('setIsLoadingList', true);

            return api_server.getClient()
                .then((client) => {
                    return client.ListContacts(filter, null, GetListConfig())
                        .then((list) => {
                            commit("setListData", list.data)
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => {
                            commit('setIsLoadingList', false)
                        });
                })
        },

        createItem({commit}, data) {
            commit('setIsCreating', true);
            commit('setCreateErrors', {});

            return api_server.getClient()
                .then((client) => {
                    return client.CreateContact(null, data)
                        .then((createdItem) => {
                            commit('setView', createdItem.data.data);
                            return createdItem.data.data
                        })
                        .catch((error) => {
                            commit('setCreateErrors', error.response.data.errors);
                            throw error;
                        })
                        .finally(() => {
                            commit('setIsCreating', false)
                        });
                })
        },

        readItem({commit}, id) {
            if (!id) {
                commit('setView', null);
                return;
            }

            commit('setIsLoadingItem', true);

            return api_server.getClient()
                .then((client) => {
                    client.ViewSettings({'id': id})
                        .then((list) => {
                            commit("setView", list.data.data)
                        })
                        .catch((error) => {
                            commit('setView', null);
                            throw error;
                        })
                        .finally(() => {
                            commit('setIsLoadingItem', false)
                        });
                })
        },

        updateItem({commit}, id, data) {

        },

        deleteItem({commit}, id) {

        }
    }
}